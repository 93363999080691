// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bantul-js": () => import("./../../../src/pages/bantul.js" /* webpackChunkName: "component---src-pages-bantul-js" */),
  "component---src-pages-gunungkidul-js": () => import("./../../../src/pages/gunungkidul.js" /* webpackChunkName: "component---src-pages-gunungkidul-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jogja-js": () => import("./../../../src/pages/jogja.js" /* webpackChunkName: "component---src-pages-jogja-js" */),
  "component---src-pages-klaten-js": () => import("./../../../src/pages/klaten.js" /* webpackChunkName: "component---src-pages-klaten-js" */),
  "component---src-pages-kulon-progo-js": () => import("./../../../src/pages/kulon-progo.js" /* webpackChunkName: "component---src-pages-kulon-progo-js" */),
  "component---src-pages-sleman-js": () => import("./../../../src/pages/sleman.js" /* webpackChunkName: "component---src-pages-sleman-js" */),
  "component---src-pages-solo-js": () => import("./../../../src/pages/solo.js" /* webpackChunkName: "component---src-pages-solo-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wates-js": () => import("./../../../src/pages/wates.js" /* webpackChunkName: "component---src-pages-wates-js" */),
  "component---src-pages-wonosari-js": () => import("./../../../src/pages/wonosari.js" /* webpackChunkName: "component---src-pages-wonosari-js" */)
}

